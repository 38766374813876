.tournaments-arena-style1 {
    clip-path: polygon(0 20%, 100% 0%, 100% 100%, 0% 100%);
    position: relative;
}

.btn-active-cm {
    background-color: white !important;
}

/* Carrousel drops styles start */
.body-carrousel-dt {
    box-sizing: border-box;
}
  
.slider-carrousel-dt {
    width: 73vw;
    text-align: center;
    overflow: hidden;
}
  
.slides-carrousel-dt {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
}

.slides-carrousel-dt::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}
  
.slides-carrousel-dt::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 10px;
}
  
.slides-carrousel-dt::-webkit-scrollbar-track {
    background: transparent;
}
  
.slides-carrousel-dt > div {
    scroll-snap-align: start;
    flex-shrink: 0;
    width: 73vw;
    height: 75vw;
    margin-right: 50px;
    border-radius: 10px;
    background: transparent;
    transform-origin: center center;
    transform: scale(1);
    transition: transform 0.5s;
    position: relative;
    
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 100px;
}
  
/* Carrousel drops styles end */


/* Carrousel tournaments styles start */
.body-carrousel-td {
    box-sizing: border-box;
}
  
.slider-carrousel-td {
    width: 85vw;
    text-align: center;
    overflow: hidden;
}
  
.slides-carrousel-td {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
}

.slides-carrousel-td::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}
  
.slides-carrousel-td::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 10px;
}
  
.slides-carrousel-td::-webkit-scrollbar-track {
    background: transparent;
}
  
.slides-carrousel-td > div {
    scroll-snap-align: start;
    flex-shrink: 0;
    width: 85vw;
    height: 165vw;
    margin-right: 50px;
    border-radius: 10px;
    background: transparent;
    transform-origin: center center;
    transform: scale(1);
    transition: transform 0.5s;
    position: relative;
    
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 100px;
}
  
/* Carrousel tournaments styles end */