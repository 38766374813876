.news-arena-style {
    clip-path: polygon(0 10%, 100% 0, 100% 100%, 0% 100%);
    position: relative;
}

.news-arena-style1 {
    clip-path: polygon(0 15%, 100% 0%, 100% 85%, 0% 100%);
  position: relative;
}

.geeks-news {
    width: 60vw;
    height: 40vw;
    overflow: hidden;
    border-radius: 0.375rem;
}
  
.geeks-news img {
    transition: 0.4s all ease-in-out;
}
  
.geeks-news:hover img {
    transform: scale(1.15);
}

/* Carrousel styles start */

.body-carrousel-news {
    box-sizing: border-box;
}
  
.slider-carrousel-news {
    width: 100vw;
    text-align: center;
    overflow: hidden;
}
  
.slides-carrousel-news {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
}

.slides-carrousel-news::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}
  
.slides-carrousel-news::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 10px;
}
  
.slides-carrousel-news::-webkit-scrollbar-track {
    background: transparent;
}
  
.slides-carrousel-news > div {
    scroll-snap-align: start;
    flex-shrink: 0;
    width: 100vw;
    height: 80vw;
    margin-right: 15px;
    border-radius: 10px;
    background: transparent;
    transform-origin: center center;
    transform: scale(1);
    transition: transform 0.5s;
    position: relative;
    
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 100px;
}
  
/* Carrousel styles end */


/* Carrousel styles start */

.body-carrousel-newsc {
    box-sizing: border-box;
}
  
.slider-carrousel-newsc {
    width: 70vw;
    text-align: center;
    overflow: hidden;
}
  
.slides-carrousel-newsc {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
}

.slides-carrousel-newsc::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}
  
.slides-carrousel-newsc::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 10px;
}
  
.slides-carrousel-newsc::-webkit-scrollbar-track {
    background: transparent;
}
  
.slides-carrousel-newsc > div {
    scroll-snap-align: start;
    flex-shrink: 0;
    width: 50vw;
    height: 90vw;
    margin-right: 15px;
    border-radius: 10px;
    background: transparent;
    transform-origin: center center;
    transform: scale(1);
    transition: transform 0.5s;
    position: relative;
    
    display: flex;
    justify-content: center;
    font-size: 100px;
}
  
/* Carrousel styles end */