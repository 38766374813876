@tailwind base;
@tailwind components;
@tailwind utilities;

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-pagination-vertical.swiper-pagination-bullets,
.swiper-vertical>.swiper-pagination-bullets {
  right: none !important;
  left: 56px !important;
}

.swiper-pagination-bullet {
  background-color: white !important;
}

.swiper-pagination-bullet-active {
  background-color: white !important;
}

.cusor-pointer {
  cursor: pointer;
}

.btn-prev {
  width: 50px;
  height: 50px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-next {
  width: 50px;
  height: 50px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-icon-delete {
  color: red;
  background-color: #E9E9E9;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.height500px {
  height: 500px;
  margin-top: 600px !important;
}

/* style={{ position: 'absolute', zIndex: '10', height: '600px', width: '100%' }} */
.videoCarrousel {
  position: absolute;
  z-index: 10;
  height: 600px;
  width: 100%;
}

/* { height: '700px', display: 'flex', justifyContent: 'center', alignItems: 'center' } */
.itemCarrusell {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container1 {
  position: relative;
  width: 100%;
  height: 800px;
  overflow: hidden;
  padding-top: 56.25%;
  /* 16:9 Aspect Ratio */
  margin-top: 0px;
}

.responsive-iframe {
  scale: 1.2;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
}

iframe {
  border-width: 0px;
}

.iframeVideo {
  width: 100%;
  height: 100%;
  margin-top: 600px;
  scale: 2;
}

.marginNegative400px {
  margin-top: -400px;
}

.borderCard {
  border: 1px solid white;
  border-radius: 10px;
}
@media (max-width: 1100px) {

  .responsive-iframe{
    scale: 2;
  }

}
@media(max-width:900px) {
  .container1 {
    position: relative;
    width: 100%;
    height: 600px;
    overflow: hidden;
    padding-top: 56.25%;
    /* 16:9 Aspect Ratio */
    margin-top: 0px;
  }

  .responsive-iframe {
    scale: 2;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
}

@media (max-width: 768px) {

  .swiper-pagination-vertical.swiper-pagination-bullets,
  .swiper-vertical>.swiper-pagination-bullets {
    display: none;
  }

  .videoCarrousel {
    top: -650px;
  }

  .responsive-iframe{
    scale: 2.5;
  }

}

@media (max-width: 576px) {
  .videoCarrousel {
    top: -570px;
  }

  .height500px {
    height: 500px;
    margin-top: 450px !important;
  }

  .marginNegative400px {
    margin-top: -300px;
  }

  .container1{
    margin-top: 50px;
  }

}
@media (max-width: 450px) {

  .container1{
    margin-top: 70px;
  }

}
@media (max-width: 400px) {

  .container1{
    margin-top: 45px;
    height: 700px;
    background: black;
  }

}

@media (max-width: 350px) {

  .marginNegative400px {
    margin-top: -200px;
  }
  .container1{
    margin-top: 100px;
  }

}

.btnIcon {
  background: linear-gradient(90deg, rgba(254, 212, 0, 1) 0%, rgba(255, 84, 9, 1) 100%);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes breath {
  0% {
    scale: 1;
  }

  50% {
    scale: 1.2;
  }

  100% {
    scale: 1;
  }
}

.animationBreath {
  animation: breath 1.5s infinite ease-out;
}

@media (max-height: 670px) {

  .popUpInitial{
    /* margin-top: 80px; */
    height: 600px ;
  }
  .popUpContainer{
    height: 530px !important; 
  }

}