.bg-booking {
    background-image: url("../../assets/icons/06_hexagon.svg"), url("../../assets/icons/06_hexagon.svg"), url("../../assets/icons/06_hexagon.svg"), url("../../assets/icons/06_hexagon.svg");
    background-position: -120px 5px, 200px -100px, 250px 750px, -100px 1250px;
    background-repeat: no-repeat;
}

.btn-category-booking {
    text-align: left;
    position: relative;
    color: #F9F9F9;
    border: #F9F9F9 1px solid;
    border-radius: 10px;
    padding: 12px;
    height: 170px;
    font-size: 14px;
    width: 100%;
    clip-path: polygon(0 23px, 20px 0, 100% 0, 100% calc(100% - 23px),calc(100% - 19px) 100%, 0 100%);
}

.btn-category-booking:after {
    content: "";
    position: absolute;
    height: 65px;
    width: 300px;
    top: -50px;
    right: -74px;
    border-right: 1px solid #F9F9F9;
    transform: rotate(40deg);
    transform-origin: left top;
}

.btn-category-booking:before {
    content: "";
    position: absolute;
    height: 65px;
    width: 300px;
    top: -213px;
    left: -193px;
    border-right: 1px solid #F9F9F9;
    transform: rotate(42deg);
    transform-origin: left top;
}

.btn-category-booking:hover {
    background-color: #71717282;
}

.border-cut {
    position: relative;
}

.border-cut:before {
    content: '';
    position: absolute;
    top: 2;
    left: 0;
    border-top: 13px solid red;
    border-right: 13px solid transparent;
    width: 0;
}

@media (min-width: 768px) {
    .bg-booking {
        background-position: 1% 16%, 92% 32%, 1% 88%, 92% 120%;
        background-size: 20vw;
    }
}

.active{
    background-color: #00000057 !important;
}