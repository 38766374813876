.bg-arena-homepi {
    background-color: rgb(14, 14, 14);
}

.home-arena-style {
    clip-path: polygon(0% 2%, 100% 0%, 100% 100%, 0% 100%);
    position: relative;
}

@media (min-width: 768px) {
    .home-arena-style{
        clip-path: polygon(0 5%, 100% 0, 100% 100%, 0% 100%);
        position: relative;
    }
}

/* Carrousel styles start */

.body-carrousel-popup {
    box-sizing: border-box;
}
  
.slider-carrousel-popup {
    width: 80vw;
    text-align: center;
    overflow: hidden;
}
  
.slides-carrousel-popup {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
}

.slides-carrousel-popup::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}
  
.slides-carrousel-popup::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 10px;
}
  
.slides-carrousel-popup::-webkit-scrollbar-track {
    background: transparent;
}
  
.slides-carrousel-popup > div {
    scroll-snap-align: start;
    flex-shrink: 0;
    width: 80vw;
    height: auto;
    margin-right: 15px;
    border-radius: 10px;
    background: transparent;
    transform-origin: center center;
    transform: scale(1);
    transition: transform 0.5s;
    position: relative;
    
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 100px;
}
  
/* Carrousel styles end */



/* Carrousel realeses styles start */

#more-realeses-body {
    overflow: hidden;
}

#more-realeses-container {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
}

#more-realeses-container::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 10px;
}
  
#more-realeses-container::-webkit-scrollbar-track {
    background: transparent;
}

/* Carrousel realeses styles end */



/* Carrousel drops styles start */

#more-drops-body {
    overflow: hidden;
}

#more-drops-container {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
}

#more-drops-container::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 10px;
}
  
#more-drops-container::-webkit-scrollbar-track {
    background: transparent;
}

/* Carrousel drops styles end */



/* Carrousel videos styles start */

#more-videos-body {
    overflow: hidden;
}

#more-videos-container {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
}

#more-videos-container::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 10px;
}
  
#more-videos-container::-webkit-scrollbar-track {
    background: transparent;
}

/* Carrousel videos styles end */