@font-face {
  font-family: 'Ghotam';
  src: local('Ghotam'), url(./fonts/GothamLight.otf) format('opentype');
}

/* @font-face {
    font-family: 'Ghotam';
    font-weight: 900;
    src: local('Ghotam'), url(./fonts/GothamBold.otf) format('opentype');
} */

/* @font-face {
    font-family: 'Ghotam';
    font-weight: 900;
    src: local('Ghotam'), url(./fonts/GothamBlack.otf) format('opentype');
} */

.App {
  font-family: Ghotam, Arial, serif;
  text-align: center;
  overflow-x: hidden;
  overflow-y: auto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.bg-arena-white { background-color: #F9F9F9; }
.bg-arena-gray { background-color: #262626; }
.bg-arena-red { background-color: #B63737; }
.bg-arena-orange { background-color: #FF5409; }
.bg-arena-yellow { background-color: #FED400; }
.bg-arena-black { background-color: #000000; }
.bg-arena-primary { background: linear-gradient(90deg, rgba(254,212,0,1) 0%, rgba(255,84,9,1) 100%); }

.bg-arena-mp { background-color: #b63737cc; }
.bg-arena-xo { background-color: #40B637cc; }
.bg-arena-xos { background-color: #1d8016cc; }
.bg-arena-pc { background-color: #FED400cc; }
.bg-arena-pcstm { background-color: #0063b9cc; }
.bg-arena-ps4 { background-color: #A137B6cc; }
.bg-arena-ps5 { background-color: #b01474cc; }
.bg-arena-ndo { background-color: #FF5409cc; }
.bg-arena-aod { background-color: #09a5ffcc; }
.bg-arena-ios { background-color: #213173cc; }
.bg-arena-nj { background-color: #000000e6; }

.bg-arena-cgrayo { background-color: #262626e5; }
.bg-arena-grayo { background-color: rgba(9, 9, 9, 0.823)}
.bg-arena-cgray { background-color: #3D3C3C; }

.txt-arena-white { color: #F9F9F9; }
.txt-arena-gray { color: #262626; }
.txt-arena-cgray { color: #717172; }
.txt-arena-red { color: #B63737; }
.txt-arena-orange { color: #FF5409; }
.txt-arena-yellow { color: #FED400; }
.txt-arena-black { color: #000000; }

/* .parallax {
  background-image:
    url("./assets/icons/06_hexagon.svg"),
    url("./assets/icons/06_hexagon.svg"),
    url("./assets/icons/06_hexagon.svg"),
    url("./assets/icons/06_hexagon.svg"),
    url("./assets/icons/06_hexagon.svg"),
    url("./assets/icons/06_hexagon.svg");
  
  background-position: -13vw -6vw, -12vw 70vw, 70vw 10vw, 70vw -82vw, -13vw -6vw, 70vw 88vw;
  background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat, no-repeat;
  background-attachment: fixed, fixed, fixed, fixed, fixed, fixed;
  height: 100%; 
}

@media (min-width: 768px) {
  .parallax {
    background-position: 13vw 4vw, 15vw 30vw, 70vw 10vw, 69vw 35vw, 13vw 62vw, 70vw 72vw;
    background-size: 15vw;
  }
} */

.glassmorphism {
  background: #71717282;
  backdrop-filter: blur( 25px );
  -webkit-backdrop-filter: blur( 4px );
  border-radius: 5px;
}

.glassmorphism-white {
  background: #cfcfcf4c;
  backdrop-filter: blur( 7px );
  -webkit-backdrop-filter: blur( 4px );
  border-radius: 10px;
}

.glassmorphism-black {
  background: #0000004e;
  backdrop-filter: blur( 25px );
  -webkit-backdrop-filter: blur( 25px );
  border-radius: 10px;
}

.btn-arena-primary {
  background: linear-gradient(90deg, rgba(254,212,0,1) 0%, rgba(255,84,9,1) 100%);
  color: #F9F9F9;
  width: 100%;
  padding: 10px;
  border-radius: 35px;
  font-size: 14px;
  box-shadow: rgba(254,212,0,1) 0px 0px 10px 0px, rgba(255,84,9,1) 10px 0px 20px -10px;
}

.btn-arena-secondary {
  background: transparent;
  color: #F9F9F9;
  width: 100%;
  padding: 10px;
  border-radius: 35px;
  border: #F9F9F9 2px solid;
  font-size: 14px;
}

.btn-arena-sixkarma {
  background: linear-gradient(90deg, rgba(181,254,0,1) 0%, rgb(119, 165, 0) 100%);
  color: #F9F9F9;
  width: 100%;
  padding: 10px;
  border-radius: 35px;
  font-size: 14px;
  box-shadow: rgba(181,254,0,1) 0px 0px 10px 0px, rgba(119, 165, 0) 10px 0px 20px -10px;
}

input[type="date"] {
  color: rgb(243 244 246);
  padding-right: 2px;
}

input[type="time"] {
  color: rgb(243 244 246);
  padding-right: 2px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: url("./assets/icons/booking/27_icon_calendar.svg") no-repeat right transparent;
  -webkit-appearance: none;
  padding-left: 2vw
}

input[type="time"]::-webkit-calendar-picker-indicator {
  background: url("./assets/icons/booking/28_icon_clock.svg") no-repeat right transparent;
  -webkit-appearance: none;
}

#select-arena {
  background: url("./assets//icons/booking/26_icon_dropdown.svg") no-repeat right transparent;
  -webkit-appearance: none;
}

#select-arenaD {
  background: url("./assets//icons/booking/26_icon_dropdown.svg") no-repeat right transparent;
  -webkit-appearance: none;
}

#select-arenaM {
  background: url("./assets//icons/booking/26_icon_dropdown.svg") no-repeat right transparent;
  -webkit-appearance: none;
}

#select-arenaMC {
  background: url("./assets//icons/booking/26_icon_dropdown.svg") no-repeat right transparent;
  -webkit-appearance: none;
}

#select-arenaDC {
  background: url("./assets//icons/booking/26_icon_dropdown.svg") no-repeat right transparent;
  -webkit-appearance: none;
}

#select-arena option {
  background-color: #3D3C3C;
  color: rgb(207, 211, 217);
  font-size: 14px;
}

#select-arenaD option {
  background-color: #3D3C3C;
  color: rgb(207, 211, 217);
  font-size: 14px;
}

#select-arenaM option {
  background-color: #3D3C3C;
  color: rgb(207, 211, 217);
  font-size: 14px;
}

#select-arenaMC option {
  background-color: #3D3C3C;
  color: rgb(207, 211, 217);
  font-size: 14px;
}

#select-arenaDC option {
  background-color: #3D3C3C;
  color: rgb(207, 211, 217);
  font-size: 14px;
}

.img-boxs {
  box-shadow: rgba(0, 0, 0, 0.7) 0px 5px 15px;
}

.img-resp {
  max-width: 100%;
  height: auto;
  width: 100%;
}

.input-group {
  display: flex;
  align-content: stretch;
}

.input-group > input {
  flex: 1 0 auto;
}

.input-group-addon {
  background: transparent;
  border-bottom: 1px solid rgb(243 244 246);
}


/* Video player start */

/* .react-player {
  width: auto;
  height: auto;
  margin: auto;
  position: relative;
}

.react-player video {
  position: relative;
  left: 0;
  top: 0;
  width: auto;
  height: auto;
  transform: none;
} */

/* Video player end */

/* Dropdown styles start */
.dropbtn {
  background-color: rgb(240, 240, 240);
  padding: 3px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {background-color: #f1f1f1}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: gray;
  color: white;
}

/* Dropdown styles start */


/* Scroll styles start */

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: gray;
}

::-webkit-scrollbar-thumb {
  background: black;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.818);
}

/* Scroll styles end */



/* Image hexagon */

.bg-img-hexag {
  width: 250px;
}
canvas{
  width: 100% !important;
  height: 100% !important;
}
@media (min-width: 768px) {
  .bg-img-hexag {
    width: 25vw;
  }
}
.lineMenubottom{
  border-bottom: 1px solid white;
}
.lineHover--white:hover{
 text-decoration: underline;
}


