.geeks-realeses {
    width: 14vw;
    height: 20vw;
    overflow: hidden;
    border-radius: 0.375rem;
}
  
.geeks-realeses img {
    transition: 0.4s all ease-in-out;
}
  
.geeks-realeses:hover img {
    transform: scale(1.15);
}



/* Carrousel styles start */

.body-carrousel-rel {
    box-sizing: border-box;
}
  
.slider-carrousel-rel {
    width: 85vw;
    text-align: center;
    overflow: hidden;
}
  
.slides-carrousel-rel {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
}

.slides-carrousel-rel::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}
  
.slides-carrousel-rel::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 10px;
}
  
.slides-carrousel-rel::-webkit-scrollbar-track {
    background: transparent;
}
  
.slides-carrousel-rel > div {
    scroll-snap-align: start;
    flex-shrink: 0;
    width: 55vw;
    height: 100vw;
    margin-right: 15px;
    border-radius: 10px;
    background: transparent;
    transform-origin: center center;
    transform: scale(1);
    transition: transform 0.5s;
    position: relative;
    
    display: flex;
    justify-content: center;
    font-size: 100px;
}
  
/* Carrousel styles end */